/**
 * Parses the text of a system message to extract title, description, URL, and image.
 * Assumes the system message text is formatted with labels followed by colon.
 * @param {string} text - formatted string from system message.
 * @returns {Object} Parsed message with title, description, URL, and image.
 */
export function parseSystemMessage(text) {
  console.log('Parsing system message:', text);  // Log the raw text

  try {
    const titleRegex = /Title: (.*?), Description:/;
    const descriptionRegex = /Description: (.*?), URL:/;
    const urlRegex = /URL: (.*?), Image:/;
    const imageRegex = /Image: (.*)$/;

    const titleMatch = titleRegex.exec(text);
    const descriptionMatch = descriptionRegex.exec(text);
    const urlMatch = urlRegex.exec(text);
    const imageMatch = imageRegex.exec(text);

    return {
      title: titleMatch ? titleMatch[1].trim() : 'No Title Provided',
      description: descriptionMatch ? descriptionMatch[1].trim() : 'No Description Provided',
      url: urlMatch ? urlMatch[1].trim() : '#',
      image: imageMatch ? imageMatch[1].trim() : 'default-image.png'
    };
  } catch (error) {
    console.error('Error parsing system message:', error);
    return {
      title: 'Error',
      description: 'Failed to parse message content.',
      url: '#',
      image: 'default-image.png'
    };
  }
}