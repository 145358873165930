import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import './ControlPanel.css';

const ControlPanel = () => {
    const { currentUser, login, logout, authError, clearError } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userRole, setUserRole] = useState('viewer'); // Default to viewer
    const navigate = useNavigate();

    useEffect(() => {
        // Set styles based on login state
        if (!currentUser) {
            document.body.style.backgroundImage = "url('/lcarsloggedout.png')";
            document.body.style.backgroundSize = 'cover';
            document.body.style.backgroundRepeat = 'no-repeat';
            document.body.style.backgroundPosition = 'center';
            document.body.style.backgroundAttachment = 'fixed';

            // Hide scrollbar
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.backgroundImage = 'none'; // Remove background when logged in

            // Show scrollbar
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.backgroundImage = 'none'; // Cleanup on unmount
            document.body.style.overflow = 'auto'; // Reset overflow on unmount
        };
    }, [currentUser]);

    useEffect(() => {
        if (currentUser) {
            currentUser.getIdTokenResult(true).then((idTokenResult) => {
                const role = idTokenResult.claims.role || 'viewer';
                setUserRole(role);
            });
        }
    }, [currentUser]);

    const handleLogin = (event) => {
        event.preventDefault();
        clearError();
        login(email, password);
    };

    const handleLogout = () => {
        logout();
        setEmail('');
        setPassword('');
    };

    // Array of page links (you can add more pages as needed)
    const pages = [
        { name: 'User Management', path: '/firebase', description: 'User Account Management', roles: ['admin', 'editor', 'viewer'] },
        { name: 'Pitch Deck', path: '/deck', description: 'Pitch Deck', roles: ['admin', 'editor'] },
        { name: 'ROI Calculator', path: '/roi', description: 'ROI Calculator', roles: ['admin', 'editor'] },
        { name: 'API AI Test', path: '/testinterface', description: 'Open AI Test Interface', roles: ['admin'] },
    ];

    // Filter pages based on user role
    const filteredPages = pages.filter((page) => page.roles.includes(userRole));

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', flexDirection: 'column', padding: '20px' }}>
            {!currentUser ? (
                <div style={{ border: '1px solid #ddd', padding: '20px', borderRadius: '5px', backgroundColor: 'black', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
                    <form onSubmit={handleLogin} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            style={{ padding: '10px', fontSize: '16px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            style={{ padding: '10px', fontSize: '16px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                        <button type="submit" style={{ padding: '10px', fontSize: '16px', borderRadius: '4px', cursor: 'pointer', backgroundColor: 'lightgray', color: 'black', border: 'none' }}>
                            Login
                        </button>
                    </form>
                    {authError && <p style={{ color: 'red', marginTop: '10px' }}>{authError}</p>}
                    {/* Home Button Below Login */}
                    <button
                        onClick={() => navigate('/')}
                        style={{
                            marginTop: '15px',
                            padding: '10px',
                            fontSize: '16px',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            backgroundColor: '#lightgray',
                            color: 'black',
                            border: 'none',
                            width: '100%', // Match the width to the login button
                            boxSizing: 'border-box' // Ensure the padding doesn't affect the width
                        }}
                    >
                        Main Site
                    </button>
                </div>
            ) : (
                <>
                    <div style={{ position: 'fixed', bottom: '10px', left: '10px', display: 'flex', gap: '10px' }}>
                        <button
                            onClick={handleLogout}
                            style={{
                                padding: '10px',
                                fontSize: '16px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                border: '1px solid #000000',
                                backgroundColor: 'white',
                                zIndex: 1000,
                            }}
                        >
                            Logout
                        </button>
                        {/* Home Button Next to Logout */}
                        <button
                            onClick={() => navigate('/')}
                            style={{
                                padding: '10px',
                                fontSize: '16px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                border: '1px solid #000000',
                                backgroundColor: 'white',
                                zIndex: 1000,
                            }}
                        >
                            Home
                        </button>
                    </div>

                    <div className="control-panel-container" style={{ paddingBottom: '80px' }}>
                        <header className="control-panel-header">
                            <h1>Control Panel</h1>
                            <p>User Role: {userRole.charAt(0).toUpperCase() + userRole.slice(1)}</p>
                        </header>

                        <div className="control-panel-content">
                            {/* Section for navigation to existing pages */}
                            <section className="page-links-section">
                                <h2>Admin Tools & Tests</h2>
                                <div 
    className="page-links-grid"
    style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between', // Ensure buttons are evenly spaced
        gap: '10px', // Adjust the gap between buttons as needed
        padding: '10px',
    }}
>
    {filteredPages.map((page, index) => (
        <div 
            key={index} 
            className="page-card" 
            onClick={() => navigate(page.path)} 
            style={{
                flex: '1 1 calc(25% - 10px)', // Set each button to take up 25% of the row minus the gap
                maxWidth: 'calc(25% - 10px)', // Ensure buttons don't exceed 25% of the container
                height: '40px', 
                backgroundColor: 'gray', 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                borderRadius: '4px', 
                color: 'white', 
                fontSize: '12px', 
                textAlign: 'center',
                boxSizing: 'border-box', 
            }}
        >
            {page.name}
        </div>
    ))}
</div>

                            </section>

                            {/* Section for widgets */}
                            <section className="widgets-section" style={{ marginTop: '20px' }}>
                                <h2>Admin Widgets (Coming Soon)</h2>
                                <div className="widgets-placeholder" style={{ padding: '10px' }}>
                                    <p>Add widgets or additional functionality here.</p>
                                </div>
                            </section>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ControlPanel;