import React, {useState, useEffect} from 'react';
import {Avatar, Box, Button} from '@mui/material';
import {styled} from '@mui/material/styles';
import LoadText from './LoadText';
import axios from 'axios';
import {useQuiz} from './QuizContext'; // Import the context hook

const StartButton = styled(Button)({
  width: '300px',  // Confirm fixed width
  height: '45px',  // Confirm fixed height
  padding: '8px 15px', // Internal padding
  color: '#fff',
  backgroundColor: '#607D8B',
  borderColor: '#000000',
  borderWidth: 2,
  borderStyle: 'solid',
  borderRadius: '16px',
  '&:hover': {
    backgroundColor: '#546E7A',
  },
});

const CenteredButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',  // Centers horizontally
  alignItems: 'center',      // Centers vertically
  marginTop: '50px',         // Adjust vertical positioning if needed
});

const Introduction = ({setShowIntroduction}) => {
  const [introduction, setIntroduction] = useState([]);
  const [currentIntroIndex, setCurrentIntroIndex] = useState(0);
  const [showStartButton, setShowStartButton] = useState(false);
  const {fetchQuestions, resetQuiz} = useQuiz();

  // Function to handle start button click
  const handleStartButtonClick = () => {
    resetQuiz();
    fetchQuestions(); // Fetch questions when start is clicked
    setShowIntroduction(false); // Optionally hide introduction
    setShowStartButton(false); // Hide start button after clicking
  };

  useEffect(() => {
    axios.get('/introduction.json')
      .then((response) => {
        setIntroduction(response.data);
      })
      .catch((error) => console.error('Failed to load introduction data:', error));
  }, []);

  useEffect(() => {
    if (currentIntroIndex === introduction.length - 1) {
      const timer = setTimeout(() => {
        setShowStartButton(true);
      }, 1500);
      return () => {
        clearTimeout(timer); // Cleanup the timer
      };
    }
  }, [currentIntroIndex, introduction.length]);

  const handleCompleteIntro = () => {
    if (currentIntroIndex < introduction.length - 1) {
      setCurrentIntroIndex(currentIntroIndex + 1);
    } else {
      console.log('All introductions shown, ready for Start button');
    }
  };

  return (
    <>
      {introduction.slice(0, currentIntroIndex + 1).map((intro, index) => (
        <Box key={index} sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          my: 1,
        }}>
          <Avatar src="/chatavatar.png" sx={{mr: 2, width: 32, height: 32}} />
          <Box sx={{
            bgcolor: '#e8eaed',
            borderRadius: '20px',
            borderTopLeftRadius: '5px',
            p: 2,
            my: 1,
            width: 'fit-content',
            maxWidth: '80%',
            marginLeft: 1,
            marginRight: 'auto',
            border: '1px solid #b8b9ba',
            display: 'flex',
            alignItems: 'center',
          }}>
            <LoadText
              text={intro.text}
              speed={150}
              onComplete={() => index === currentIntroIndex && handleCompleteIntro()}
            />
          </Box>
        </Box>
      ))}
      {showStartButton && (
        <CenteredButtonContainer>
          <StartButton onClick={handleStartButtonClick}>
            Let's Begin
          </StartButton>
        </CenteredButtonContainer>
      )}
    </>
  );
};

export default Introduction;