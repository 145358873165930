import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, Box, Card, CardActionArea, CardContent, CssBaseline, Grid, Typography} from '@mui/material';
import React, {useState} from 'react';

function Resources() {
    const panelContents = [
        {
            title: 'Medical and Behavioral Support',
            image: 'group01.png',
            cards: [
                {title: 'Pediatric Mental Health', description: 'Access comprehensive health resources tailored for children.', url: '/resourceplaceholder'},
                {title: 'Child Psychology Services', description: 'Connect with professional child psychologists for early intervention.', url: '/resourceplaceholder'},
                {title: 'Behavioral Therapy for Children', description: 'Explore therapy and counseling services for behavioral challenges.', url: '/resourceplaceholder'}
            ]
        },
        {
            title: 'Technology Based Solution',
            image: 'group02.png',
            cards: [
                {title: 'Therapeutic Apps for Children', description: 'Discover apps designed to help children manage anxiety and stress.', url: '/resourceplaceholder.com'},
                {title: 'Educational Games for Mental Health', description: 'Engage your child with games that promote emotional well-being.', url: '/resourceplaceholder'},
                {title: 'Online Support Platforms', description: 'Find online platforms offering support groups and resources for children and parents.', url: '/resourceplaceholder'}
            ]
        },
        {
            title: 'Educational Support',
            image: 'group03.png',
            cards: [
                {title: 'Special Education Resources', description: 'Resources for parents and teachers of children with special educational needs.', url: '/resourceplaceholder'},
                {title: 'Learning Disabilities Support', description: 'Access tools and communities for learning disabilities.', url: '/resourceplaceholder'},
                {title: 'Educational Workshops on Child Mental Health', description: 'Participate in workshops designed to educate on child mental health issues.', url: '/resourceplaceholder'}
            ]
        },
        {
            title: 'Coaching and Organizational Support',
            image: 'group04.png',
            cards: [
                {title: 'Parent Coaching for Mental Health', description: 'Gain insights and strategies for supporting your child’s mental health.', url: '/resourceplaceholder'},
                {title: 'Child Development Coaching', description: 'Professional guidance on fostering mental and emotional development.', url: '/resourceplaceholder'},
                {title: 'Organizational Skills for Children', description: 'Tools and strategies to help children organize and reduce stress.', url: '/resourceplaceholder'}
            ]
        },
        {
            title: 'Lifestyle and Self-Help',
            image: 'group05.png',
            cards: [
                {title: 'Mindfulness for Children', description: 'Learn about mindfulness practices suited for children.', url: '/resourceplaceholder'},
                {title: 'Healthy Lifestyle Programs', description: 'Programs designed to promote a healthy lifestyle among children.', url: '/resourceplaceholder'},
                {title: 'Self-Help Strategies for Youngsters', description: 'Simple self-help techniques that children can practice.', url: '/resourceplaceholder'}
            ]
        },
        {
            title: 'Community and Social Support',
            image: 'group06.png',
            cards: [
                {title: 'Community Mental Health Programs', description: 'Join community-based programs focused on child mental health.', url: '/resourceplaceholder'},
                {title: 'Social Skills Groups for Children', description: 'Connect your child with groups that enhance social skills and emotional intelligence.', url: '/resourceplaceholder'},
                {title: 'Volunteer for Child Mental Health', description: 'Opportunities to volunteer in organizations focused on child mental health.', url: '/resourceplaceholder'}
            ]
        }
    ];

    const getRandomBackgroundImage = () => {
        const backgrounds = [
            '/background1.jpg',
            '/background2.jpg',
            '/background3.jpg',
            '/background4.jpg',
            '/background5.jpg',
            '/background6.jpg',
        ];
        const randomIndex = Math.floor(Math.random() * backgrounds.length);
        return backgrounds[randomIndex];
    };

    const [expandedPanels, setExpandedPanels] = useState(new Map(panelContents.map((panel) => [panel.title, false])));

    const handleChange = (panel) => (event, isExpanded) => {
        setExpandedPanels(new Map(expandedPanels.set(panel, isExpanded)));
    };

    return (
        <div>
            <CssBaseline />
            <Box sx={{bgcolor: '#A8C0D0', padding: 2, borderRadius: '15px'}}>
                <Grid container spacing={2}>
                    {panelContents.map((panel, index) => (
                        <Grid item xs={12} sm={12} md={12} key={index}>
                            <Accordion
                                expanded={expandedPanels.get(panel.title)}
                                onChange={handleChange(panel.title)}
                                sx={{backgroundColor: '#A8C0D0', boxShadow: 'none'}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}a-content`}
                                    id={`panel${index}a-header`}
                                    sx={{backgroundColor: '#e0e0e0', borderRadius: '25px', display: 'flex', alignItems: 'center'}}
                                >
                                    <img src={panel.image} alt={panel.title} style={{width: '30px', height: '30px', marginRight: '8px'}} />
                                    <Typography sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
                                        {panel.title}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {panel.cards.map((card, idx) => (
                                        <Card
                                            key={idx}
                                            sx={{
                                                width: '100%',
                                                marginBottom: 2,
                                                backgroundImage: `url(${getRandomBackgroundImage()})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center'
                                            }}
                                        >
                                            <CardActionArea component="a" href={card.url} target="_blank" rel="noopener noreferrer">
                                                <CardContent>
                                                    <Typography gutterBottom variant="h6" sx={{color: '#FFFFFF'}}>
                                                        {card.title}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{color: '#FFFFFF'}}>
                                                        {card.description}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
    );
}

export default Resources;