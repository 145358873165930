import React, {useState, useEffect} from 'react';
import {AppBar, Toolbar, Button, Box, Typography, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {useLocation, useNavigate} from 'react-router-dom'; // Import useLocation and useNavigate

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:900px)');
  const location = useLocation(); // Get the current path
  const navigate = useNavigate(); // Use navigate for navigation
  const currentPath = location.pathname; // Get the current page path

  // State for handling the click/touch pattern
  const [clickCount, setClickCount] = useState(0);
  const [timer, setTimer] = useState(null);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // List of navigation items
  const navItems = [
    {name: 'Home', path: '/'},
    {name: 'For Parents', path: '/parents'},
    {name: 'For Business', path: '/business'},
    {name: 'Chat', path: '/chat'},
    {name: 'Contact Us', path: '/contact'},
  ];

  // Filter out the current page from the navigation items
  const filteredNavItems = navItems.filter((item) => item.path !== currentPath);

  // Handle touch/click events for the pattern recognition on the AUXMentis logo
  const handleLogoClick = () => {
    // Increment the click count
    setClickCount((prevCount) => prevCount + 1);

    // Reset the pattern if not completed within 3 seconds
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      setClickCount(0);
    }, 3000);
    setTimer(newTimer);

    // Check if the 5th click is reached
    if (clickCount + 1 === 5) {
      setClickCount(0); // Reset the counter
      navigate('/admin'); // Navigate to the admin page
    }
  };

  // Keyboard shortcut for desktop access (e.g., Alt + Shift + A)
  useEffect(() => {
    const handleKeydown = (event) => {
      if (event.altKey && event.shiftKey && event.key === 'A') {
        navigate('/admin'); // Navigate to the hidden admin link
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => window.removeEventListener('keydown', handleKeydown);
  }, [navigate]);

  return (
    <AppBar
      position="static"
      sx={{bgcolor: 'white', borderBottom: '1px solid #e0e0e0', position: 'relative'}}
    >
      <Toolbar sx={{justifyContent: 'space-between', padding: {xs: '0 10px', sm: '0 20px'}}}>
        {/* Logo and Company Name */}
        <Box sx={{display: 'flex', alignItems: 'center', my: 2}}>
          <Box
            component="img"
            src="/auxmentis.png"
            alt="AUXMentis Logo"
            sx={{width: {xs: 40, sm: 60, md: 80}, height: {xs: 40, sm: 60, md: 80}, marginRight: 2}}
            onClick={handleLogoClick} // Attach the click handler to the logo
            style={{cursor: 'pointer'}}
          />
          <Typography variant="h5" sx={{color: '#1e88e5', fontWeight: 'bold', fontSize: {xs: '1.2rem', sm: '1.5rem', md: '2rem'}}}>
            AUXMentis
          </Typography>
        </Box>

        {/* Navigation Links */}
        {isMobile ? (
          <>
            <IconButton onClick={handleDrawerToggle} edge="end" color="inherit">
              <MenuIcon sx={{color: '#1e88e5'}} />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
              <List>
                {filteredNavItems.map((item) => (
                  <ListItem button key={item.name} onClick={handleDrawerToggle}>
                    <ListItemText>
                      <Button href={item.path} sx={{color: '#1e88e5', textAlign: 'left', width: '100%'}}>
                        {item.name}
                      </Button>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </>
        ) : (
          <Box>
            {filteredNavItems.map((item) => (
              <Button key={item.name} href={item.path} sx={{color: '#1e88e5', fontWeight: 'bold', mx: 1}}>
                {item.name}
              </Button>
            ))}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;