import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import GeneralInfoPage from './components/GeneralnfoPage';
import ParentAssistancePage from './components/ParentAssistantPage';
import BusinessPage from './components/BusinessPage';
import ContactForm from './components/ContactForm';
import ControlPanel from './components/ControlPanel';
import FirebaseAdmin from './components/FirebaseAdmin';
import GoogleSlides from './components/GoogleSlides';
import GoogleSheets from './components/GoogleSheets';
import ChatbotPrototype from './components/ChatbotPrototype';
import Profile from './components/Profile';
import Resources from './components/Resources';
import Health from './components/Health';
import Community from './components/Community';
import Behavioral from './components/Behavioral';
import ADHD from './components/ADHD';
import Anxiety from './components/Anxiety';
import Depression from './components/Depression';
import BehavioralDetails from './components/BehavioralDetails';
import ADHDDetails from './components/ADHDDetails';
import AnxietyDetails from './components/AnxietyDetails';
import DepressionDetails from './components/DepressionDetails';
import Signup from './components/Signup';
import CheckAuth from './components/CheckAuth';
import TestInterface from './components/TestInterface';
import UserCheck from './components/UserCheck';
import ResourcePlaceholder from './components/ResourcePlaceholder';
import './font.css';


function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<GeneralInfoPage/>} />
      <Route path="/parents" element={<ParentAssistancePage/>} />
      <Route path="/business" element={<BusinessPage/>} />
      <Route path="/contact" element={<ContactForm/>} />
      <Route path="/admin" element={<ControlPanel/>} />
      <Route path="/firebase" element={<CheckAuth><FirebaseAdmin/></CheckAuth>} />
      <Route path="/deck" element={<GoogleSlides/>} />
      <Route path="/roi" element={<GoogleSheets/>} />
      <Route path="/chat" element={<ChatbotPrototype/>} />
      <Route path="/resources" element={<CheckAuth><Resources/></CheckAuth>} />
      <Route path="/profile" element={<CheckAuth><Profile/></CheckAuth>} />
      <Route path="/health" element={<CheckAuth><Health/></CheckAuth>} />
      <Route path="/community" element={<CheckAuth><Community/></CheckAuth>} />
      <Route path="/behavioral" element={<CheckAuth><Behavioral/></CheckAuth>} />
      <Route path="/adhd" element={<CheckAuth><ADHD/></CheckAuth>} />
      <Route path="/anxiety" element={<CheckAuth><Anxiety/></CheckAuth>} />
      <Route path="/depression" element={<CheckAuth><Depression/></CheckAuth>} />
      <Route path="/adhd/:adhdId" element={<CheckAuth><ADHDDetails/></CheckAuth>} />
      <Route path="/behavioral/:behavioralId" element={<CheckAuth><BehavioralDetails/></CheckAuth>} />
      <Route path="/anxiety/:anxietyId" element={<CheckAuth><AnxietyDetails/></CheckAuth>} />
      <Route path="/depression/:depressionId" element={<CheckAuth><DepressionDetails/></CheckAuth>} />
      <Route path="/resourceplaceholder" element={<CheckAuth><ResourcePlaceholder/></CheckAuth>} />
      <Route path="/hiddensignup" element={<CheckAuth><Signup /></CheckAuth>} />
      <Route path="/testinterface" element={<CheckAuth><TestInterface /></CheckAuth>} />
      <Route path="/usercheck" element={<CheckAuth><UserCheck /></CheckAuth>} />
      {/* other routes will go here */}
    </Routes>
    </Router>
  );
}

export default App;
