import React, {useState, useEffect} from 'react';
import {Button, Box, Typography, Container, TextField, CircularProgress} from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import {analytics} from '../firebase';
import {logEvent} from 'firebase/analytics';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Log a page view event when the component mounts
        logEvent(analytics, 'page_view', {
          page_title: 'Contact',
          page_location: window.location.href,
          page_path: window.location.pathname,
        });
      }, []);
    
    useEffect(() => {
        document.body.style.backgroundColor = 'white';
        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);

    // Handle form input changes
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch('https://formsubmit.co/peter@auxmentis.com', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setStatus('Message sent successfully! Redirecting you back home...');
                setFormData({name: '', email: '', phone: '', message: ''});

                // Wait 3 seconds, then redirect to the homepage
                setTimeout(() => {
                    window.location.href = '/';
                }, 3000);
            } else {
                setStatus('Failed to send the message.');
            }
        } catch (error) {
            setStatus('Error sending the message.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="lg">
            {/* Header with Navigation Bar */}
            <Header />

            {/* Contact Form */}
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    maxWidth: 600,
                    mx: 'auto',
                    p: 3,
                    backgroundColor: '#f9f9f9',
                    borderRadius: '8px',
                    mt: 6, // Adjust this value to move the form down
                }}
            >
                <Typography variant="h4" gutterBottom align="center" sx={{color: '#1e88e5'}}>
                    Contact AUXMentis
                </Typography>

                <TextField
                    fullWidth
                    label="Full Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    sx={{mb: 2}}
                />

                <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    sx={{mb: 2}}
                />

                <TextField
                    fullWidth
                    label="Phone Number (Optional)"
                    name="phone"
                    type="tel"
                    value={formData.phone}
                    onChange={handleChange}
                    sx={{mb: 2}}
                />

                <TextField
                    fullWidth
                    label="Your Message"
                    name="message"
                    multiline
                    rows={4}
                    value={formData.message}
                    onChange={handleChange}
                    required
                    sx={{mb: 2}}
                />

                {/* Submit Button or Loading Spinner */}
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        disabled={loading}
                        sx={{backgroundColor: '#1e88e5', color: 'white', padding: '10px'}}
                    >
                        {loading ? 'Sending...' : 'Submit Form'}
                    </Button>
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: '#1e88e5',
                                position: 'absolute',
                                right: 16,
                            }}
                        />
                    )}
                </Box>

                {/* Status Message */}
                {status && (
                    <Typography variant="body1" sx={{mt: 2, color: status.includes('successfully') ? 'green' : 'red', textAlign: 'center'}}>
                        {status}
                    </Typography>
                )}
            </Box>

            {/* Footer */}
            <Footer />

        </Container>
    );
};

export default ContactForm;