import {Box, CssBaseline} from '@mui/material';
import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {AuthContext} from './AuthContext';
import {useQuiz} from './QuizContext'; // Import useQuiz from QuizContext
import {useOverlay} from './OverlayContext';

function Navigation() {
  const {currentUser, resetIntroduction} = useContext(AuthContext);
  const {resetQuiz} = useQuiz(); // Use the resetQuiz function from QuizContext
  const {showOverlay, hideOverlay} = useOverlay();

  const handleInternalLinkClick = (event, path) => {
    if (path === '/chat') {
      showOverlay();  // Immediately show the overlay
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('scrollToBottomEvent'));  // Dispatch the scroll event after 3000ms
        setTimeout(hideOverlay, 700);  // Hide the overlay after scrolling begins
      }, 2750);
    }

    if (path === '#reset') {
      event.preventDefault();
      console.log('Resetting Introduction...'); // Debug: Check if this line is reached
      resetIntroduction();  // Use the resetIntroduction from AuthContext
      return;
    }
    if (path === '#refresh') {  // Handling the reset quiz action
      event.preventDefault();
      console.log('Resetting Quiz...'); // Debug: Resetting the quiz
      resetQuiz();  // Use the resetQuiz function from QuizContext
      return;
    }
    if (!currentUser) {
      event.preventDefault();
      alert('You must be logged in to access this page.');
    }
  };

  const navigationItems = [
    {path: '#refresh', imgSrc: '/auxmentis.png', alt: 'Auxmentis'}, // Changed alt text for clarity
    {path: '/chat', imgSrc: '/home.png', alt: 'Home'},
    {path: '/profile', imgSrc: '/profile.png', alt: 'Profile'},
    {path: '/community', imgSrc: '/community.png', alt: 'Community'},
    {path: '#reset', imgSrc: '/reset.png', alt: 'Reset Introduction'}  // Reset button
  ];

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    maxWidth: '35rem',
    margin: 'auto',
    marginTop: '10px',
    marginBottom: '10px',
    paddingTop: '10px',
    paddingBottom: '5px',
    borderRadius: '15px',
    overflow: 'hidden',
    backgroundColor: '#E0E0E0',
    '@media (max-width: 600px)': {width: '100%'},
    '@media (min-width: 600px)': {width: '90%'},
  };

  return (
    <div>
      <CssBaseline />
      <Box sx={containerStyle}>
        {navigationItems.map((link, index) => (
          <Link key={index} to={link.path} onClick={(e) => handleInternalLinkClick(e, link.path)}>
            <img src={link.imgSrc} alt={link.alt} style={{
              height: '60px',
              flexGrow: 1,
              alignSelf: 'center'
            }} />
          </Link>
        ))}
      </Box>
    </div>
  );
}

export default Navigation;