import React, {createContext, useContext, useState, useEffect} from 'react';
import axios from 'axios';

// Context with default values that match the provided values in the provider.
const QuizContext = createContext({
  questions: [],
  setQuestions: () => {}, // Ensure this is defined here for completeness
  currentQuestionIndex: 0,
  setCurrentQuestionIndex: () => {},
  conversationHistory: [],
  score: 0, // Initial score state
  setScore: () => {}, // Function to update the score
  setConversationHistory: () => {},
  fetchQuestions: async () => {},
  resetQuiz: () => {},
  setQuizFinished: () => {},
  quizFinished: false,
});

export const QuizProvider = ({children}) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
    JSON.parse(localStorage.getItem('currentQuestionIndex')) || 0
  );
  const [conversationHistory, setConversationHistory] = useState(
    JSON.parse(localStorage.getItem('conversationHistory')) || []
  );
  const [score, setScore] = useState(0); // State to hold score
  const [quizFinished, setQuizFinished] = useState(false);

  // Persist currentQuestionIndex and conversationHistory to localStorage
  useEffect(() => {
    localStorage.setItem('currentQuestionIndex', JSON.stringify(currentQuestionIndex));
    localStorage.setItem('conversationHistory', JSON.stringify(conversationHistory));
  }, [currentQuestionIndex, conversationHistory]);

  // Function to fetch questions from an API
  const fetchQuestions = async () => {
    try {
      const response = await axios.get('/questions.json');
      setQuestions([response.data.initialQuestion]);  // Only set initial question
      localStorage.setItem('questionSets', JSON.stringify(response.data.questionSets));  // Store the sets in localStorage for later use
    } catch (error) {
      console.error('Error fetching questions', error);
    }
  };

  // Function to reset the quiz to initial state
  const resetQuiz = () => {
    // Clear relevant localStorage items
    localStorage.removeItem('currentQuestionIndex');
    localStorage.removeItem('conversationHistory');
    localStorage.removeItem('questions');

    // Reset states to initial values
    setCurrentQuestionIndex(0);
    setConversationHistory([]);
    setQuestions([]);
    setScore(0);
    setQuizFinished(false);  // Explicitly set quizFinished to false on reset
    fetchQuestions(); // Re-fetch questions
  };

  return (
    <QuizContext.Provider value={{
      questions,
      setQuestions,
      currentQuestionIndex,
      setCurrentQuestionIndex,
      conversationHistory,
      setConversationHistory,
      score,
      setScore,
      fetchQuestions,
      resetQuiz,
      setQuizFinished,
      quizFinished
    }}>
      {children}
    </QuizContext.Provider>
  );
};

export const useQuiz = () => useContext(QuizContext);